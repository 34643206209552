import React from 'react';
import useStores from '../../hooks/useStores';
import './styles/CheckoutFormInput.scss';
import { observer } from 'mobx-react';
import { identifyUser, initiateCheckout } from '../../services/Klaviyo';
import Translate from '../Translate/Translate';
import { CheckIcon } from '@heroicons/react/20/solid';

interface Props {
  name?: string;
  placeholder?: string;
  disabled?: boolean;
}

const CheckoutFormInput = ({ name, placeholder, disabled }: Props) => {
  const { checkout, cart, authentication } = useStores();

  const handleFromChange = (e) => {
    checkout.setValue(e.target.name, e.target.value);
  };

  const validateInput = (e) => {
    checkout.validateValue(e.target.name, e.target.value);

    if (e.target.name === 'email' && checkout.emailValid) {
      identifyUser(checkout.email, checkout.firstname, checkout.lastname);
      startedCheckout();
    }
  };

  const startedCheckout = () => {
    initiateCheckout(cart, authentication.loggedIn);
  };

  return (
    <div className={`component-checkoutFormInput ${checkout[name + 'Valid']}`}>
      <div className="component-checkoutFormInput__inner">
        <input
          className="component-checkoutFormInput__input"
          name={name}
          value={checkout[name]}
          placeholder={placeholder}
          type="text"
          onChange={handleFromChange}
          onBlur={validateInput}
          disabled={disabled}
        />
        <label
          className={`component-checkoutFormInput__label${
            checkout[name] ? '--active' : ''
          }`}
        >
          {!name ? (
            placeholder
          ) : (
            <Translate component="checkout" keyword={name} />
          )}
        </label>
        {checkout[name + 'Valid'] === 'ok' ? (
          <CheckIcon className="absolute right-5 top-1/2 h-6 w-6 fill-rgreen -translate-y-1/2" />
        ) : null}
      </div>
    </div>
  );
};

export default observer(CheckoutFormInput);
