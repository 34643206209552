import React, { useState, useEffect } from 'react';
import useStores from '../../hooks/useStores';
import Image from '../Image';
import { Sizes } from '../../constants/Image';
import LocalizedLink from '../LocalizedLink';
import Price from '../Price/Price';
import QuantityButton from './QuantityButton';
import Translate from '../Translate/Translate';
import './styles/CheckoutProducts.scss';
import { observer } from 'mobx-react';
import DiscountCode from './DiscountCode';
import OpenModalFromMail from '../Wishlist/OpenModalFromMail';
import { useSearchParams } from 'react-router-dom';
import Modal from '../Modal';
import SendToMail from '../Wishlist/SendToMail';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

const CheckoutProducts = () => {
  const { cart, authentication, user } = useStores();
  const [cartItems, setCartItems] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');

  useEffect(() => {
    setCartItems(cart.variations);
  }, [cart.variations]);

  const removeProduct = async (variation, quantity) => {
    await cart.removeItem(variation.code, quantity);
    setCartItems(cart.variations);
  };

  const hasShopRole = user.roles.includes('shop');

  return (
    <div className="component-checkoutProducts">
      <div className="component-checkoutProducts-header">
        <div className="component-checkoutProducts-header__section">
          <p>
            <Translate component="checkout" keyword="th-products" />
          </p>
        </div>
        <div className="component-checkoutProducts-header__section">
          <p>
            <Translate component="checkout" keyword="th-amount" />
          </p>
        </div>
        <div className="component-checkoutProducts-header__section">
          <p>
            <Translate component="checkout" keyword="th-price" />
          </p>
        </div>
        <div className="component-checkoutProducts-header__section">
          <p>
            <Translate component="checkout" keyword="th-sum" />
          </p>
        </div>
      </div>
      <div className="component-checkoutProducts-list">
        {cartItems.length ? (
          cartItems.map((variation, index) => {
            const product = cart.getProduct(variation.product_id);

            if (product) {
              return (
                <div key={index} className="component-checkoutProducts-item">
                  <LocalizedLink
                    className="component-checkoutProducts-image"
                    to={`/toode/${product.slug}`}
                  >
                    <Image
                      className={
                        'rounded bg-rgrey-lightlight object-cover max-w-32 md:max-w-52 min-w-32 h-auto w-auto'
                      }
                      defaultSize={Sizes.W800}
                      image={product.cover}
                      allSizes={[Sizes.W300, Sizes.W540, Sizes.W800]}
                    />
                  </LocalizedLink>
                  <div className="component-checkoutProducts-content">
                    <div className="component-checkoutProducts-titles">
                      <p className="component-checkoutProducts-brandTitle">
                        {product.brandTitle} {product.gridTitle}
                      </p>
                      <h3 className="component-checkoutProducts-productTitle">
                        {product.title}
                      </h3>
                    </div>
                    <div className="component-checkoutProducts-sizes">
                      <span className="component-checkoutProducts-size">
                        {variation.value}
                      </span>
                      <QuantityButton data={variation} />
                    </div>
                    <Price
                      prices={product.prices(true)}
                      priceLabel={true}
                      discountPriceClassname="text-base mr-1"
                      defaultPriceClassName="text-xs text-nowrap"
                      discountLabelClassname="text-xs"
                    />
                    {product.product_code !== '4370' &&
                    product.product_code !== '4340' ? (
                      <div className="component-checkoutProducts-remove__desktop">
                        <p
                          onClick={() =>
                            removeProduct(variation, variation.quantity)
                          }
                        >
                          <Translate
                            component="checkout"
                            keyword="remove-product"
                          />
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="component-checkoutProducts-desktop">
                    {product.product_code !== '4370' &&
                    product.product_code !== '4340' ? (
                      <QuantityButton data={variation} />
                    ) : (
                      <div />
                    )}
                    <Price
                      prices={product.prices(true)}
                      priceLabel={true}
                      discountPriceClassname="text-base mr-1"
                      defaultPriceClassName="text-sm"
                      discountLabelClassname="text-xs"
                    />
                    <p className="component-checkoutProducts-desktop__sum">
                      {(
                        variation.quantity *
                        product.finalPrice(authentication.loggedIn)
                      ).toFixed(2)}{' '}
                      €
                    </p>
                  </div>
                  {product.product_code !== '4370' &&
                  product.product_code !== '4340' ? (
                    <div
                      className="component-checkoutProducts-remove"
                      onClick={() =>
                        removeProduct(variation, variation.quantity)
                      }
                    ></div>
                  ) : null}
                </div>
              );
            }
          })
        ) : (
          <div className="component-checkoutProducts-empty">
            <h3>
              <Translate component="mini-cart" keyword="empty" />
            </h3>
          </div>
        )}
      </div>
      {hasShopRole && cartItems.length ? (
        <div className="md:my-2 lg:pb-5">
          <Modal
            buttonClass="font-axi-bold py-3 text-black hover:underline cursor-pointer flex items-center"
            button="Saada ostukorv kliendile"
            content={
              <SendToMail
                onClose={() => {
                  cart.load();
                }}
              />
            }
            contentTitle={'Kliendi andmed'}
            modalClass="center overflow-auto min-h-fit"
            icon={<EnvelopeIcon className="h-5 w-5 mr-2" />}
          />
        </div>
      ) : null}

      <div className="component-checkoutProducts-footer">
        <DiscountCode />
        <div className="component-checkoutProducts-footer__titles">
          <p>
            <Translate component="checkout" keyword="right-total" />
            <span>{cart.totalSum} €</span>
          </p>
        </div>
      </div>
      <OpenModalFromMail
        uuid={uuid}
        onClose={() => {
          setSearchParams({});
          cart.load();
        }}
      />
    </div>
  );
};

export default observer(CheckoutProducts);
