import React from 'react';
import { observer } from 'mobx-react';
import './styles/CheckoutBottomSummary.scss';
import useStores from '../../hooks/useStores';
import Translate from '../Translate/Translate';
import CheckoutPaymentBtn from './CheckoutPaymentBtn';

const CheckoutBottomSummary = () => {
  const { cart, checkout } = useStores();

  const paymentMethodTitle = () => {
    let method;
    let title;
    if (checkout.paymentMethods) {
      method = checkout.paymentMethods.find(
        (option) => option.name === checkout.paymentMethod,
      );
      title = method.title;
    }
    return title;
  };

  return (
    <div className="component-checkoutBottomSummary">
      <h3>
        <Translate component="checkout" keyword="summary" />
      </h3>
      <div className="component-checkoutBottomSummary__container">
        {checkout.shippingMethodLabel && checkout.parcelOptionLabel ? (
          <div className="component-checkoutBottomSummary__parcel">
            <p>
              <Translate component="checkout" keyword="delivery-method" />
            </p>
            <span>{checkout.shippingMethodLabel}</span>
            <span>{checkout.parcelOptionLabel}</span>
          </div>
        ) : null}
        {checkout.shippingGroup === 'rademar' && checkout.shippingMethod ? (
          <div className="component-checkoutBottomSummary__rademar">
            <p>
              <Translate component="checkout" keyword="delivery-method" />
            </p>
            <span>{checkout.shippingMethodLabel}</span>
            <span>
              Värvi 14, Tallinn |{' '}
              <span className="text-rgreen">E-R 09:00 - 16:00</span>
            </span>
          </div>
        ) : null}
        {checkout.shippingCity &&
        checkout.shippingAddress &&
        checkout.shippingZip ? (
          <div className="component-checkoutBottomSummary__courier">
            <p>
              <Translate component="checkout" keyword="delivery-method" />
            </p>
            <p>{checkout.shippingMethodLabel}</p>
            <p>
              Linn: <span>{checkout.shippingCity}</span>
            </p>
            <p>
              Aadress: <span>{checkout.shippingAddress}</span>
            </p>
            <p>
              Postiindeks: <span>{checkout.shippingZip}</span>
            </p>
          </div>
        ) : null}
        {checkout.paymentMethod ? (
          <div className="component-checkoutBottomSummary__method">
            <p>
              <Translate component="checkout" keyword="payment-method-title" />
            </p>
            <span>{paymentMethodTitle()}</span>
          </div>
        ) : null}

        <div className="component-checkoutBottomSummary__sum">
          <p>
            <Translate component="checkout" keyword="right-products" />:
            <span>{cart.nrOfAllItems} tk</span>
          </p>
          <p>
            <Translate component="checkout" keyword="right-sum" />
            <span>{cart.originalTotal} €</span>
          </p>
          {cart.totalDiscount == 0 ? null : (
            <p>
              <Translate component="checkout" keyword="discount-sum" />
              <span> -{cart.totalDiscount} €</span>
            </p>
          )}
          <p>
            <Translate component="checkout" keyword="right-delivery" />
            <span>{cart.shippingTotal} €</span>
          </p>
          <p>
            <Translate component="checkout" keyword="right-total" />
            <span>{cart.totalSum} €</span>
          </p>
        </div>
        <CheckoutPaymentBtn />
      </div>
    </div>
  );
};

export default observer(CheckoutBottomSummary);
