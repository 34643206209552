import Ajax from '../services/Ajax';
import DiscountCode from '../models/DiscountCode';

export async function validateDiscountCode(
  code: string,
): Promise<DiscountCode | number> {
  try {
    const response = await Ajax.get(`discount_codes/${code}/validate`);
    return new DiscountCode(response.data);
  } catch (err) {
    return err.response.status;
    // Invalid discount code
  }
}

export async function removeDiscountCode(code: string) {
  try {
    await Ajax.post(`discount_codes/${code}/remove`);
  } catch (error) {
    // This error can be silently ignored
    console.error(error);
  }
}
