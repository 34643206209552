import { observer } from 'mobx-react';
import React, { useState } from 'react';
import useStores from '../../hooks/useStores';
import {
  validateDiscountCode,
  removeDiscountCode,
} from '../../requests/discountCodes';
import Translate from '../Translate/Translate';
import './styles/DiscountCode.scss';

const DiscountCode = () => {
  const { cart } = useStores();
  const [error, setError] = useState(false);
  const [discountCode, setDiscountCode] = useState('');

  const handleChange = (e) => {
    setDiscountCode(e.target.value);
  };

  const resetCode = async () => {
    await removeDiscountCode(cart.discountSummary.discountCode.code);
    await cart.load();
    setDiscountCode('');
  };

  const validateCode = async () => {
    const response = await validateDiscountCode(discountCode);

    if (response === 404) {
      setError(true);
    } else {
      await cart.load();
      setError(false);
    }
  };

  let currentDiscountCode = null;
  if (cart && cart.discountSummary && cart.discountSummary.discountCode) {
    currentDiscountCode = cart.discountSummary.discountCode;
  }

  return (
    <div className="component-discountCode">
      {currentDiscountCode ? (
        <div className="component-discountCode__validation">
          <div className="component-discountCode__validation-title">
            <p>
              <Translate component="discountcode" keyword="activated" />
            </p>
          </div>
          <p>{cart.discountSummary.description}</p>
          <div className="component-discountCode__validation-remove">
            <p onClick={resetCode}>
              <Translate component="checkout" keyword="remove-product" />
            </p>
          </div>
        </div>
      ) : (
        <div className="component-discountCode__container">
          <input
            className="component-discountCode__input"
            type="text"
            name="discount-code"
            id="discount-code"
            onChange={handleChange}
            value={discountCode}
          />
          {discountCode ? null : (
            <label className="component-discountCode__label">
              <Translate component="discountcode" keyword="code" />
            </label>
          )}
          <button
            disabled={discountCode.length >= 1 ? false : true}
            className="component-discountCode__button"
            onClick={validateCode}
          >
            <p className="component-discountCode__button-title">
              <Translate component="discountcode" keyword="activate" />
            </p>
          </button>
        </div>
      )}

      {error ? (
        <div className="component-discountCode__validation-fail">
          <p>
            <Translate component="discountcode" keyword="not-valid" />
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default observer(DiscountCode);
