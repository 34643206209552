import { when } from 'mobx';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import useStores from '../../hooks/useStores';
import Alert, { Type } from '../Alert';
import Translate from '../Translate/Translate';
import CheckoutFormInput from './CheckoutFormInput';
import Select from 'react-select';
import './styles/DeliveryOptions.scss';

const DeliveryOptions = () => {
  const { checkout, cart, shipping } = useStores();
  const [loading, setLoading] = useState(false);
  const [shippingList, setShippingList] = useState([]);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  useEffect(() => {
    shipping.loadShippingMethods();
    when(
      () => cart.initialized && !!shipping.methods.length,
      () => {
        if (cart.shippingMethod) {
          handleShippingMethod(
            { target: { value: cart.shippingMethod.slug } },
            cart.shippingMethod.group,
            cart.shippingMethod.name,
          );
        }
        setLoading(true);
      },
    );
  }, []);

  const resetMethod = async () => {
    try {
      await cart.setShippingMethod('reset_shipping');
    } catch (error) {
      //Fail silently
    }

    checkout.setValues([
      { name: 'shippingMethod', value: '' },
      { name: 'shippingMethodLabel', value: '' },
      { name: 'shippingOption', value: '' },
      { name: 'shippingOptionValid', value: '' },
      { name: 'shippingGroup', value: '' },
      { name: 'shippingCity', value: '' },
      { name: 'shippingAddress', value: '' },
      { name: 'shippingZip', value: '' },
      { name: 'shippingCityValid', value: '' },
      { name: 'shippingAddressValid', value: '' },
      { name: 'shippingZipValid', value: '' },
    ]);
  };

  const handleShippingMethod = async (e, group, label) => {
    const shippingMethod = e.target.value;

    if (group !== checkout.shippingMethod) {
      checkout.setValues([
        { name: 'shippingOption', value: '' },
        { name: 'parcelOptionLabel', value: '' },
      ]);
    }
    checkout.setValues([
      { name: 'shippingMethod', value: shippingMethod },
      { name: 'shippingMethodLabel', value: label },
      { name: 'shippingGroup', value: group },
      {
        name: 'shippingMethodHasOptions',
        value: shipping.hasOptions(shippingMethod),
      },
    ]);

    checkout.validateValue('shippingMethod', shippingMethod);

    const shippingOptions = await shipping.loadOptions(shippingMethod);

    setShippingList(shippingOptions);

    cart.setShippingMethod(e.target.value);
  };

  const onShippingOptionChange = (event) => {
    checkout.setValue('shippingOption', event.value);
    checkout.setValue('parcelOptionLabel', event.label);
    checkout.validateValue('shippingOption', event.value);
  };

  const deliveryOptions = (group) => {
    let options;
    let title;

    if (cart.availableShipping) {
      options = cart.availableShipping
        .filter((value) => value.group === group)
        .map((item, index) => {
          title = <Translate component="checkout" keyword={item.group} />;
          const icon = item.logo;

          return (
            <div key={index} className="component-deliveryOptions-shipper">
              <input
                type="radio"
                value={item.slug}
                name="shipping"
                onChange={(e) => handleShippingMethod(e, item.group, item.name)}
                className="component-deliveryOptions-shipper__input"
              />
              <label className="component-deliveryOptions-shipper__label">
                <div className="component-deliveryOptions-shipper__logo">
                  <img src={icon} alt={item.name} />
                </div>
                <div className="component-deliveryOptions-shipper__titles">
                  <p>{item.name}</p>
                  <p>
                    <Translate component="checkout" keyword="th-price" />:{' '}
                    {item.base_price.toFixed(2)}€
                  </p>
                </div>
                <div className="component-deliveryOptions-shipper__checkbox" />
              </label>
            </div>
          );
        });
    }

    return (
      <div className="component-deliveryOptions-item">
        <h4>{title}</h4>
        {options}
      </div>
    );
  };

  const optionsList = () => {
    const options = shippingList.map((option) => ({
      value: option.value,
      label: option.name,
      isDisabled: option.disabled,
    }));

    let parcelInfo;
    if (checkout.shippingOption && shippingList) {
      parcelInfo = shippingList.find(
        (parcel) => parcel.value === checkout.shippingOption,
      );
    }

    let validationError = false;

    if (checkout.shippingOptionValid === 'error') {
      validationError = true;
    }

    const customPlaceholder = isSelectOpen ? (
      <p className="text-rgrey">
        <Translate component="search" keyword="search" />
      </p>
    ) : (
      <Translate component="checkout" keyword="choose-parcel" />
    );

    return (
      <>
        <Select
          key={checkout.shippingMethod}
          options={options}
          onChange={onShippingOptionChange}
          onMenuOpen={() => setIsSelectOpen(true)}
          onMenuClose={() => setIsSelectOpen(false)}
          className="component-deliveryOptions-dropdown"
          classNamePrefix="component-deliveryOptions-dropdown"
          placeholder={customPlaceholder}
        />
        {parcelInfo ? (
          <div className="component-deliveryOptions-shipper__details">
            <p>
              <Translate
                component="checkout"
                keyword="parcel-additional-info"
              />
            </p>
            {parcelInfo.address ? (
              <p>
                <Translate component="checkout" keyword="aadress" />:{' '}
                {parcelInfo.address}
              </p>
            ) : null}
            {parcelInfo.availability ? (
              <p>
                <Translate
                  component="checkout"
                  keyword="parcel-opening-title"
                />{' '}
                {parcelInfo.availability}
              </p>
            ) : null}
            {parcelInfo.additional ? <p>{parcelInfo.additional}</p> : null}
          </div>
        ) : null}
        <Alert show={!!validationError} type={Type.ERROR}>
          <Translate component="checkout" keyword="checkout-option" />
        </Alert>
      </>
    );
  };

  const shippingFormFields = () => {
    let validationError = false;

    if (
      checkout.shippingCityValid === 'error' ||
      checkout.shippingAddressValid === 'error' ||
      checkout.shippingZipValid === 'error'
    ) {
      validationError = true;
    }

    return (
      <div className="component-deliveryOptions-shippingForm">
        <div className="component-deliveryOptions-shippingForm__container">
          <CheckoutFormInput name="shippingCity" />
          <CheckoutFormInput name="shippingAddress" />
          <CheckoutFormInput name="shippingZip" />
        </div>
        <Alert show={!!validationError} type={Type.ERROR}>
          <Translate component="checkout" keyword="required-fields" />
        </Alert>
      </div>
    );
  };

  const selectedOption = () => {
    let selectedMethod;

    if (cart.availableShipping) {
      selectedMethod = cart.availableShipping.find(
        (option) => option.slug === checkout.shippingMethod,
      );

      const icon = selectedMethod.logo;

      return (
        <div className="component-deliveryOptions-selected">
          <div className="component-deliveryOptions-selected__option">
            <div className="component-deliveryOptions-selected__logo">
              <img src={icon} alt={selectedMethod.name} />
            </div>
            <div className="component-deliveryOptions-selected__titles">
              <p>{selectedMethod.name}</p>
              {selectedMethod.slug === 'rademar-delivery-station' ? (
                <p className="component-deliveryOptions-selected__rademar">
                  <Translate component="checkout" keyword="rademar-free" />
                </p>
              ) : (
                <p>
                  <Translate component="checkout" keyword="th-price" />:{' '}
                  {selectedMethod.base_price.toFixed(2)}€
                </p>
              )}
            </div>
            <input
              type="checkbox"
              checked
              className="h-6 w-6 rounded border-rgrey-light text-rgreen focus:ring-rgreen cursor-pointer ml-auto"
              onClick={resetMethod}
            />
          </div>
          {checkout.shippingGroup === 'parcel' && checkout.shippingMethod
            ? optionsList()
            : null}
          {checkout.shippingGroup === 'courier' && checkout.shippingMethod
            ? shippingFormFields()
            : null}
        </div>
      );
    }
  };

  if (!loading) {
    return null;
  }

  let validationError = false;

  if (
    checkout.shippingGroupValid === 'error' ||
    checkout.shippingMethodValid === 'error'
  ) {
    validationError = true;
  }

  return (
    <div className="component-deliveryOptions">
      <div className="component-deliveryOptions-header">
        <h3 className="component-deliveryOptions-header__title">
          <Translate component="checkout" keyword="my-shipping" />
        </h3>
        {/** TODO: Enable checkout country selection after LV testing */}
        {checkout.shippingMethod ? (
          <div className="ml-auto">
            <p
              className="border rounded text-sm text-black bg-white cursor-pointer px-5 py-2.5 border-solid border-rgrey-light font-axi-bold hover:bg-rgreen hover:text-white"
              onClick={resetMethod}
            >
              <Translate component="checkout" keyword="change-option" />
            </p>
          </div>
        ) : null}
      </div>
      {checkout.shippingGroup && checkout.shippingMethod ? (
        selectedOption()
      ) : (
        <div className="component-deliveryOptions-list">
          <div className="component-deliveryOptions-group__content">
            {deliveryOptions('parcel')}
            {deliveryOptions('courier')}
          </div>
          <Alert show={!!validationError} type={Type.ERROR}>
            <Translate component="checkout" keyword="checkout-shipping-error" />
          </Alert>
        </div>
      )}
    </div>
  );
};

export default observer(DeliveryOptions);
