import React, { useEffect, useState } from 'react';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import useStores from '../../hooks/useStores';
import Translate from '../Translate/Translate';
import Alert, { Type } from '../Alert';
import Modal from '../Modal';
import InBankModal from '../Product/InBankModal';
import ClickAndTryModal from '../Product/ClickAndTryModal';
import './styles/PaymentMethods.scss';

const PaymentMethods = () => {
  const { checkout, cart } = useStores();
  const [loaded, setLoaded] = useState(false);
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    checkout.loadPaymentMethods().then((methods) => {
      if (methods && methods.length) {
        setLoaded(true);
      }
    });

    when(
      () => cart.initialized,
      () => {
        initiateCheckoutGA();
      },
    );
  }, []);

  const paymentMethodChange = (event) => {
    checkout.setValue(event.target.name, event.target.value);
  };

  const initiateCheckoutGA = () => {
    sendDataToGTM({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'EUR',
        value: cart.totalSum,
        items: cart.variations.map((variation) => {
          const product = cart.getProduct(variation.product_id);

          const finalPrice = product.finalPrice(true);
          const defaultPrice = product.defaultPrice;
          const discountAmount = defaultPrice - finalPrice;

          return {
            item_id: variation.product_id,
            item_variant: variation.value,
            quantity: variation.quantity,
            item_name: product.brandTitle + ' ' + product.title,
            item_brand: product.brandTitle,
            price: defaultPrice,
            discount: Number(discountAmount.toFixed(2)),
          };
        }),
      },
    });
  };

  const resetMethod = () => {
    checkout.setValue('paymentMethod', '');
  };

  const method = (type) => {
    let title;

    let options;
    if (checkout.paymentMethods) {
      options = checkout.paymentMethods
        .filter((method) => method.type === type)
        .map((opt, index) => {
          if (opt.name === 'liisi_ee') {
            return;
          }

          if (opt.type) {
            title = (
              <Translate component="checkout" keyword={`${opt.type}-payment`} />
            );
          } else {
            return;
          }

          const icon = opt.logo;

          if (opt.name === 'inbank-split-3') {
            return (
              <div key={index} className="component-paymentMethods__option">
                <input
                  type="radio"
                  name="paymentMethod"
                  value={opt.name}
                  onChange={paymentMethodChange}
                  checked={opt.name === checkout.paymentMethod ? true : false}
                />
                <label className="component-paymentMethods__label">
                  <img className="h-6 w-auto" src={icon} alt={opt.name} />
                  <div className="text-rpink text-xs md:text-sm mx-1 p-1 md:mx-3 md:p-2 border-l text-nowrap whitespace-nowrap">
                    3<span className="text-xs"> &#10005; </span>
                    {(cart.totalSum / 3).toFixed(2)} €
                  </div>
                </label>
              </div>
            );
          }
          if (opt.name === 'inbank-split-6') {
            return (
              <div key={index} className="component-paymentMethods__option">
                <input
                  type="radio"
                  name="paymentMethod"
                  value={opt.name}
                  onChange={paymentMethodChange}
                  checked={opt.name === checkout.paymentMethod ? true : false}
                />
                <label className="component-paymentMethods__label">
                  <img className="h-6 w-auto" src={icon} alt={opt.name} />
                  <div className="text-rpink text-xs md:text-sm mx-1 p-1 md:mx-3 md:p-2 text-nowrap border-l whitespace-nowrap">
                    6<span className="text-xs"> &#10005; </span>
                    {(cart.totalSum / 6).toFixed(2)} €
                  </div>
                </label>
              </div>
            );
          }
          if (opt.name === 'inbank-next-month') {
            return (
              <div key={index} className="relative w-full">
                <input
                  className="absolute w-full h-full opacity-0 cursor-pointer inset-1"
                  type="radio"
                  name="paymentMethod"
                  value={opt.name}
                  onChange={paymentMethodChange}
                  checked={opt.name === checkout.paymentMethod ? true : false}
                />
                <label className="flex border rounded border-rgrey-light h-16 justify-center items-center mx-1 my-2">
                  <img className="h-6 w-auto ml-2" src={icon} alt={opt.name} />
                  <div className="text-rpink text-xs md:text-sm mx-1 md:mx-3 md:p-2 p-1 border-l">
                    <Translate
                      component="checkout"
                      keyword="next-month-payment"
                    />
                  </div>
                </label>
              </div>
            );
          }
          if (opt.name === 'modena') {
            return (
              <div key={index} className="relative w-full">
                <input
                  className="absolute w-full h-full opacity-0 cursor-pointer inset-1"
                  type="radio"
                  name="paymentMethod"
                  value={opt.name}
                  onChange={paymentMethodChange}
                  checked={opt.name === checkout.paymentMethod ? true : false}
                />
                <label className="flex border rounded border-rgrey-light h-16 justify-center items-center mx-1 my-2">
                  <img className="h-6 w-auto ml-2" src={icon} alt={opt.name} />
                  <div className="text-xs md:text-sm mx-1 md:mx-3 md:p-2 p-1 border-l">
                    <Translate component="checkout" keyword="modena-payment" />
                  </div>
                </label>
              </div>
            );
          }
          return (
            <div key={index} className="component-paymentMethods__option">
              <input
                type="radio"
                name="paymentMethod"
                value={opt.name}
                onChange={paymentMethodChange}
                checked={opt.name === checkout.paymentMethod ? true : false}
              />
              <label className="component-paymentMethods__label">
                <div className={`component-paymentMethods__image--${opt.name}`}>
                  <img className="h-6 w-auto" src={icon} alt={opt.name} />
                </div>
              </label>
            </div>
          );
        });
    }

    const hasModenaPayment = checkout.paymentMethods.some((paymentMethod) => {
      return paymentMethod.name === 'modena';
    });

    const hasSplitPayment = checkout.paymentMethods.some((paymentMethod) => {
      return paymentMethod.name === 'inbank-split-3';
    });

    return (
      <div className="w-full">
        <div className="flex gap-4 items-baseline my-2 ml-2">
          <h4>{title}</h4>
          {type === 'modena' && hasModenaPayment && (
            <Modal
              buttonClass="rounded-full text-rgrey border border-rgrey shrink-0 w-5 h-5 text-center py-0.5 justify-center items-center hover:text-white hover:bg-black hover:border-black hover:cursor-pointer"
              button="i"
              content={<ClickAndTryModal />}
              contentTitle={''}
              modalClass="center overflow-auto min-h-96 md:min-h-fit"
            />
          )}
          {type === 'payLater' && hasSplitPayment && (
            <Modal
              buttonClass="rounded-full text-rgrey border border-rgrey shrink-0 w-5 h-5 text-center py-0.5 justify-center items-center hover:text-white hover:bg-black hover:border-black hover:cursor-pointer"
              button="i"
              content={<InBankModal price={cart.totalSum} />}
              contentTitle={
                <img
                  src="https://rademar-1b47c.kxcdn.com/icons/inbank.svg"
                  alt="inbank"
                  className="h-10 w-auto m-5"
                />
              }
              modalClass="center overflow-auto min-h-96 md:min-h-fit"
            />
          )}
        </div>
        <div className="flex flex-wrap">{options}</div>
      </div>
    );
  };

  let payOption;
  if (checkout.paymentMethod) {
    const method = checkout.paymentMethods.find(
      (option) => option.name === checkout.paymentMethod,
    );

    const icon = method.logo;

    payOption = (
      <>
        <div className="flex flex-col cursor-pointer -mt-10 md:mt-3.5 mb-0 md:mb-6 mx-0">
          <div onClick={resetMethod} className="flex justify-end mb-3.5">
            <p className="border rounded text-sm text-black bg-white cursor-pointer px-5 py-2.5 border-solid border-rgrey-light font-axi-bold hover:bg-rgreen hover:text-white">
              <Translate component="checkout" keyword="change-option" />
            </p>
          </div>
          <div className={`component-paymentMethods__bank--${method.name}`}>
            {method.name === 'inbank-split-3' ? (
              <div className="flex items-center flex-1 border rounded px-5 py-3 border-solid border-rgreen">
                <div className="md:flex items-center">
                  <img src={icon} alt={method.name} className="h-6 w-auto" />
                  <div className="text-rpink text-xs md:text-sm my-1 md:mx-3 text-nowrap">
                    3<span className="text-xs">&#10005;</span>
                    {(cart.totalSum / 3).toFixed(2)} €
                  </div>
                </div>
                <p className="text-sm md:leading-10 ml-5 px-5 border-l-rgrey-light border-l border-solid font-axi-bold">
                  {method.title}
                </p>
                <input
                  type="checkbox"
                  checked
                  className="h-6 w-6 rounded shrink-0 border-rgrey-light text-rgreen focus:ring-rgreen cursor-pointer ml-auto"
                  onClick={resetMethod}
                />
              </div>
            ) : method.name === 'inbank-split-6' ? (
              <div className="flex items-center flex-1 border rounded px-5 py-3 border-solid border-rgreen">
                <div className="md:flex items-center">
                  <img src={icon} alt={method.name} className="h-6 w-auto" />
                  <div className="text-rpink text-xs md:text-sm my-1 md:mx-3 text-nowrap">
                    6<span className="text-xs">&#10005;</span>
                    {(cart.totalSum / 6).toFixed(2)} €
                  </div>
                </div>
                <p className="text-sm md:leading-10 ml-5 px-5 border-l-rgrey-light border-l border-solid font-axi-bold">
                  {method.title}
                </p>
                <input
                  type="checkbox"
                  checked
                  className="h-6 w-6 rounded shrink-0 border-rgrey-light text-rgreen focus:ring-rgreen cursor-pointer ml-auto"
                  onClick={resetMethod}
                />
              </div>
            ) : (
              <div className="flex items-center flex-1 border rounded px-5 py-3 border-solid border-rgreen">
                <img
                  src={icon}
                  alt={method.name}
                  className="md:h-6 h-5 md:w-auto w-max-1/3"
                />
                <p className="text-sm md:leading-10 ml-5 px-5 border-l-rgrey-light border-l border-solid font-axi-bold">
                  {method.title}
                </p>
                {/* <div className="component-paymentMethods__checkmark ml-auto" /> */}
                <input
                  type="checkbox"
                  checked
                  className="h-6 w-6 rounded shrink-0 border-rgrey-light text-rgreen focus:ring-rgreen cursor-pointer ml-auto mr-2"
                  onClick={resetMethod}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  let validationError = false;
  if (checkout.paymentMethodValid === 'error') {
    validationError = true;
  }

  if (!loaded) {
    return null;
  }

  return (
    <div className="component-paymentMethods">
      <div className="component-paymentMethods__header">
        <div className="component-paymentMethods__buttons">
          <h3 className="component-paymentMethods__title">
            <Translate component="checkout" keyword="my-payment" />
          </h3>
        </div>
        {payOption}
      </div>
      {!payOption ? (
        <div className="component-paymentMethods__list">
          {method('modena')}
          {method('banklink')}
          {method('card')}
          {method('payLater')}
          <Alert show={!!validationError} type={Type.ERROR}>
            <Translate component="checkout" keyword="checkout-payment-error" />
          </Alert>
        </div>
      ) : null}
    </div>
  );
};

export default observer(PaymentMethods);
