import React from 'react';
import CheckoutForm from './CheckoutForm';
import CheckoutProducts from './CheckoutProducts';
import PaymentMethods from './PaymentMethods';
import CheckoutTopSummary from './CheckoutTopSummary';
import { observer } from 'mobx-react';
import DeliveryOptions from './DeliveryOptions';
import DiscountCode from './DiscountCode';
import Translate from '../Translate/Translate';
import CheckoutBottomSummary from './CheckoutBottomSummary';
import './styles/index.scss';

const Checkout = () => {
  return (
    <div className="component-checkout">
      <h2 className="component-checkout__title">
        <Translate component="checkout" keyword="table-title" />
      </h2>
      <div className="component-checkout__container">
        <div className="component-checkout__products">
          <CheckoutProducts />
        </div>
        <div className="component-checkout__header">
          <div className="component-checkout__sticky">
            <div className="component-checkout__summary">
              <CheckoutTopSummary />
            </div>
            <div className="component-checkout__discountCode">
              <DiscountCode />
            </div>
          </div>
        </div>
        <div className="component-checkout__body">
          <div className="component-checkout__body-container">
            <div className="component-checkout__body-item">
              <div className="component-checkout__box">
                <div className="component-checkout__box-title">
                  <Translate component="checkout" keyword="andmed" />
                </div>
                <div className="component-checkout__box-line" />
              </div>
              <CheckoutForm />
            </div>
            <div className="component-checkout__body-item">
              <div className="component-checkout__box">
                <div className="component-checkout__box-title">
                  <Translate component="checkout" keyword="my-shipping" />
                </div>
                <div className="component-checkout__box-line" />
              </div>
              <DeliveryOptions />
            </div>
            <div className="component-checkout__body-item">
              <div className="component-checkout__box">
                <div className="component-checkout__box-title">
                  <Translate component="checkout" keyword="my-payment" />
                </div>
                <div className="component-checkout__box-line" />
              </div>
              <PaymentMethods />
            </div>
            <div className="component-checkout__body-item">
              <div className="component-checkout__box">
                <div className="component-checkout__box-title">
                  <Translate component="checkout" keyword="summary" />
                </div>
                <div className="component-checkout__box-line" />
              </div>
              <CheckoutBottomSummary />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Checkout);
