import { observer } from 'mobx-react';
import React from 'react';
import useStores from '../../hooks/useStores';
import Translate from '../Translate/Translate';
import './styles/CheckoutTopSummary.scss';

const CheckoutSummary = () => {
  const { cart } = useStores();
  return (
    <div className="component-checkoutTopSummary">
      <div className="component-checkoutTopSummary__inner">
        <div className="component-checkoutTopSummary__prices">
          <p>
            <Translate component="checkout" keyword="right-delivery" />
            <span>{cart.shippingTotal} €</span>
          </p>
          <p>
            <Translate component="checkout" keyword="right-sum" />
            <span>{cart.originalTotal} €</span>
          </p>
          <p>
            <Translate component="checkout" keyword="right-total" />
            <span>{cart.totalSum} €</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default observer(CheckoutSummary);
