import React, { useState } from 'react';
import { observer } from 'mobx-react';
import useStores from '../../hooks/useStores';
import Alert, { Type } from '../Alert';
import Translate from '../Translate/Translate';
import LocalizedLink from '../LocalizedLink';
import { getGaId } from '../../services/Analytics';

import './styles/CheckoutPaymentBtn.scss';

const CheckoutPaymentBtn = () => {
  const { checkout, orders, payment, cart } = useStores();
  const [errorMessage, setErrorMessage] = useState(false);

  const onTermsButtonClick = () => {
    checkout.setValue('termsAgreement', !checkout.termsAgreement);
    checkout.validateValue('termsAgreement', checkout.termsAgreement);
    checkout.validateAllFields();
  };

  const testValidation = () => {
    checkout.validateAllFields();
  };

  async function onPayButtonClick() {
    const clientId = await getGaId();

    try {
      checkout.loading = !checkout.loading;
      const order = await orders.create({
        firstname: checkout.firstname,
        lastname: checkout.lastname,
        email: checkout.email,
        phone: checkout.phone,
        shipping: checkout.shippingMethod,
        shipping_option: checkout.shippingOption,
        shipping_country: checkout.shippingCountry,
        shipping_city: checkout.shippingCity,
        shipping_address: checkout.shippingAddress,
        shipping_zip: checkout.shippingZip,
        payment_method: checkout.paymentMethod,
        client_id: clientId,
      });

      const { reference } = order;

      // For card payments special checkout form must be initialized
      const paymentMethod = checkout.getPaymentMethod(checkout.paymentMethod);
      if (paymentMethod) {
        if (paymentMethod.processor === 'montonio') {
          window.location.href = (
            await payment.montonioTransaction(reference, paymentMethod.code)
          ).url;

          return;
        }
      }

      let url;

      if (
        checkout.paymentMethod === 'inbank-split-3' ||
        checkout.paymentMethod === 'inbank-split-6' ||
        checkout.paymentMethod === 'inbank-next-month'
      ) {
        url = (await payment.inbank(reference, checkout.paymentMethod)).url;
      } else if (checkout.paymentMethod === 'modena') {
        url = (await payment.modena(reference)).url;
      } else {
        url = (await payment.transaction(reference, checkout.paymentMethod))
          .url;
      }

      window.location.href = url;
    } catch (error) {
      if (error.response.status === 404) {
        setErrorMessage(true);
      } else {
        setErrorMessage(false);
      }
    }
  }

  return (
    <>
      <Alert show={!!errorMessage} type={Type.ERROR}>
        <Translate component="checkout" keyword="required-fields" />
      </Alert>
      <div
        className={`component-checkoutPaymentBtn-validation ${checkout.termsAgreementValid}`}
      >
        <input
          type="checkbox"
          className="component-checkoutPaymentBtn-validation__input"
          checked={checkout.termsAgreement}
          onChange={onTermsButtonClick}
        />
        <label className="component-checkoutPaymentBtn-validation__label">
          <div className="component-checkoutPaymentBtn-validation__checkbox" />
          <p>
            <LocalizedLink to={'/tingimused'}>
              <Translate component="checkout" keyword="terms" />
            </LocalizedLink>
          </p>
        </label>
      </div>
      <button
        className={`component-checkoutPaymentBtn${
          checkout.paymentMethod &&
          checkout.isCheckoutReady &&
          cart.variations.length
            ? ''
            : '--disabled'
        }`}
        onClick={
          checkout.paymentMethod &&
          checkout.isCheckoutReady &&
          cart.variations.length
            ? onPayButtonClick
            : testValidation
        }
      >
        <p>
          <Translate component="checkout" keyword="button" />
        </p>
      </button>
    </>
  );
};

export default observer(CheckoutPaymentBtn);
